import axios from 'axios';
import { throbberRules } from "./throbberRules";
import { createAndThrowEvent, constants } from "../utils/twoFactor";

export default class NdxRpc {
    baseUrlPath;
    storeGetter;
    store;

    /**
     * @param {String<'/ndx'|'/portal'>} baseUrlPath
     * @param {Function<Promise>} storeGetter
     */
    constructor(baseUrlPath, storeGetter) {
        if (!/irrelevant/.test(baseUrlPath)) {
            this.baseUrlPath = baseUrlPath;
            axios.defaults.baseURL = baseUrlPath + '/rpc/';
        }

        this.storeGetter = storeGetter;

        axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';
    }

    async getStore () {
        if (!this.store) {
            this.store = await this.storeGetter();
        }
        return this.store;
    };

    async errorHandler (error) {
        if (error.response && error.response.status === 403) { // forbidden
            if (error?.response?.data.two_factor_complete === false) {
                createAndThrowEvent(constants.totpRequired);
                return;
            }
            if (error?.response?.data.two_factor_setup_complete === false) {
                createAndThrowEvent(constants.setupRequired);
                return;
            }
            if (!(new RegExp(axios.defaults.baseURL + "/init$")).test(error.request.responseURL)) {
                await this.getStore();
                this.store.dispatch('session/forcedLogout');
            }
        }
        if (error.response && error.response.status === 401) {
            if (!(new RegExp("/rpc/init$")).test(error.request.responseURL)) {
                await this.getStore();
                this.store.dispatch('session/forcedLogout');
            }
        }
    };

    getCsrfToken() {
        const _secret = this.store.getters['session/getSecret'];
        let secret = '';

        if (_secret?.length) {
            secret = _secret;
        }

        return secret;
    }

    async ndxRpc(service, method, params, throbberRule = throbberRules.FULLSCREEN) {
        await this.getStore();
        this.store.dispatch('session/incrementRequestCounter', throbberRule);
        const secret = this.getCsrfToken();

        return new Promise((resolve, reject) => {
            axios.post(
                service + '/' + method + '?t=' + secret,
                {
                    method,
                    id: '1:3:5',
                    params
                }
            ).then(response => {
                if (response.data.error) {
                    switch (response.data.error.code) {
                        case 3001:
                            this.store.dispatch('session/setMaintenance', true);
                            break;
                        case 404:
                            window.dispatchEvent(
                                new CustomEvent('REDIRECT_PAGE_NOT_FOUND', {
                                    detail: {
                                        error: response.data.error
                                    }
                                })
                            );
                            reject(response.data.error);
                            break;
                        default:
                            console.log(response.data.error);
                            this.errorHandler(response);
                            reject(response.data.error);
                    }
                }
                resolve(
                    typeof response.data === 'object' && 'result' in response.data ?
                        response.data.result :
                        response.data
                );
            }).catch(error => {
                console.log(error);
                this.errorHandler(error);
                reject(error);
            }).finally(() => this.store.dispatch('session/decrementRequestCounter', throbberRule));
        });
    }

    async ndxController(url, params, throbberRule = throbberRules.FULLSCREEN) {
        await this.getStore();
        this.store.dispatch('session/incrementRequestCounter', throbberRule);
        const secret = this.getCsrfToken();

        return new Promise((resolve, reject) => {
            axios.post(
                url + '?t=' + secret,
                params
            ).then(response => {
                if (response.data.error) {
                    switch (response.data.error.code) {
                        case 3001:
                            this.store.dispatch('session/setMaintenance', true);
                            break;
                        case 404:
                            window.dispatchEvent(
                                new CustomEvent('REDIRECT_PAGE_NOT_FOUND', {
                                    detail: {
                                        error: response.data.error
                                    }
                                })
                            );
                            reject(response.data.error);
                            break;
                        default:
                            console.log(response.data.error);
                            this.errorHandler(response);
                            reject(response.data.error);
                    }
                }
                resolve(response.data.result);
            }).catch(error => {
                console.log(error);
                this.errorHandler(error);
                reject(error);
            }).finally(() => this.store.dispatch('session/decrementRequestCounter', throbberRule));
        });
    }

    async ndxUploadFile(url, formData, throbberRule = throbberRules.FULLSCREEN) {
        await this.getStore();
        this.store.dispatch('session/incrementRequestCounter', throbberRule);
        const secret = this.getCsrfToken();

        const progressCb = (progressEvt) => {
            const progress = progressEvt.loaded / progressEvt.total;
            this.store.dispatch('session/updateThrobberProgress', progress);
        };

        formData.append('t', secret);

        return new Promise((resolve, reject) => {
            axios.post(
                url,
                formData,
                {
                    baseURL: '/',
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: progressCb
                }
            ).then((result) => {
                if (typeof result.data === 'string') {
                    reject(result.data);
                } else {
                    resolve('result' in result.data ? result.data.result : result.data);
                }
            }).catch((error) => {
                reject(error);
            }).finally(() => {
                this.store.dispatch('session/decrementRequestCounter', throbberRule);
                this.store.dispatch('session/updateThrobberProgress', null);
            });
        });
    }

    ndxGet (url, withoutBaseUrl = false) {
        return new Promise((resolve, reject) => {
            axios.get(url, {baseURL: withoutBaseUrl ? '/' : axios.defaults.baseURL})
                .then((result) => {
                    if (result) {
                        resolve(result.data);
                    } else {
                        reject(new Error('no result'));
                    }
                })
                .catch((error) => {
                    this.errorHandler(error);

                    reject(error);
                });
        });
    }

    ndxTracking (trackedAction, trackedOid, additionalTrackingData) {
        return axios.post(
            this.baseUrlPath + '/tracking',
            {
                trackedAction,
                trackedOid,
                additionalTrackingData
            },
            {baseURL: '/'}
        );
    }
}
