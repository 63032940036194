<script>
    export default {
        name: "OrderItemActions",
        data() {
            return {
                localInstanceId: null,
                localInstanceVariables: null
            };
        },
        computed: {
            isInBasket() {
                return this.$store.getters['basket/getOrderItem'](this.item.orderItemId) !== null;
            },
            isInWatchlist() {
                return !this.isInBasket && this.type !== 'configurator';
            },
            leftActionBtns() {
                if (this.isInBasket) {
                    return this.calculateLeftBasketBtns();
                } else if (this.isInWatchlist) {
                    return this.calculateLeftWatchlistBtns();
                }

                return [];
            },
            rightActionBtns() {
                if (this.isInBasket) {
                    return this.calculateRightBasketBtns();
                } else if (this.isInWatchlist) {
                    return this.calculateRightWatchlistBtns();
                }

                return [];
            },
            instanceId: {
                get() {
                    return this.localInstanceId
                        ? this.localInstanceId
                        : this.item.workflowInstance?.id;
                },
                set(value) {
                    this.localInstanceId = value;
                }
            },
            instanceVariables: {
                get() {
                    return this.localInstanceVariables
                        ? this.localInstanceVariables
                        : this.item.workflowInstance?.variables;
                },
                set(value) {
                    this.localInstanceVariables = value;
                }
            },
            workflowInstanceEvents() {
                if (this.instanceId) {
                    return this.$store.getters['workflowEvents/getByInstanceId'](this.instanceId);
                }
                return [];
            },
            showInstanceDetails() {
                if (!this.instanceId) {
                    return false;
                }

                const verdict = this.getValueFromWorkflowVariables('verdict', null);

                return !this.isEditable || this.executeApproval || verdict === 'revision';
            },
            workflowBusinessOid() {
                return {
                    type: 'orderItem',
                    id: this.isInBasket ? this.item.orderItemId : this.item.id,
                    namespace: 'ApproveOrderItem',
                    timestamp: this.item.timestamp
                };
            },
            workflowData() {
                return {
                    instanceId: this.instanceId,
                    instanceVariables: this.instanceVariables,
                    startApprovalPossible: this.startApprovalPossible,
                    showInstanceDetails: this.showInstanceDetails
                };
            },
            executeApproval() {
                return this.getValueFromWorkflowVariables('executeApproval', false);
            },
            approvalRequired() {
                return this.getValueFromWorkflowVariables('approvalRequired', false);
            },
            isEditable() {
                return this.getValueFromWorkflowVariables('editable', true);
            },
            hasEditableProductFeatures() {
                return this.type !== 'configurator' && this.item.productFeatures.length && this.isEditable;
            },
            hasEditableCampaignrun() {
                return this.isEditable && this.item.campaignRun &&
                    'hasCampaignrunEditableTask' in this.item && this.item.hasCampaignrunEditableTask &&
                    this.item.permissions?.isEditable;
            },
            isBundleItem() {
                return this.bundlePath && !this.inTreeView;
            },
            inTreeView() {
                return this.inTree;
            },
            campaignRunDeleted() {
                return !!(this.item?.checks?.CAMPAIGNRUN_DELETED);
            }
        },
        watch: {
            workflowInstanceEvents(newValue) {
                let newEvents = newValue.filter(item => item.timestamp > this.timestamp);
                if (newEvents.length) {
                    if (this.isInBasket) {
                        const reloadBasket = newEvents.findIndex((item) => {
                            return item.event === 'complete';
                        }) > -1;

                        if (reloadBasket) {
                            this.$emit('refresh-basket');
                        } else {
                            this.getWorkflowInstanceVariables();
                        }
                    } else {
                        this.getWorkflowInstanceVariables();
                    }

                    this.setTimestamp();
                }
            }
        },
        created() {
            this.setTimestamp();
        },
        methods: {
            calculateLeftBasketBtns() {
                let buttons = [];

                if (this.showInstanceDetails) {
                    buttons.push({
                        action: 'instanceDetails',
                        text: this.$t('btn.workflow.instanceDetails')
                    });
                }

                if (this.startApprovalPossible) {
                    buttons.push({
                        action: 'startApproval',
                        text: this.$t('btn.workflow.startApproval')
                    });
                }

                if (this.hasEditableProductFeatures) {
                    buttons.push({
                        action: 'editOrderItemDetail',
                        text: this.$t('btn.edit')
                    });
                }

                if (this.hasEditableCampaignrun) {
                    buttons.push({
                        action: 'gotoCampaignRun',
                        text: this.$t('btn.editCustomization')
                    });
                }

                return buttons;
            },
            calculateLeftWatchlistBtns() {
                let buttons = [];

                if (this.showInstanceDetails) {
                    buttons.push({
                        action: 'instanceDetails',
                        text: this.$t('btn.workflow.instanceDetails')
                    });
                }

                if (this.startApprovalPossible) {
                    buttons.push({
                        action: 'startApproval',
                        text: this.$t('btn.workflow.startApproval')
                    });
                }

                if (this.hasEditableCampaignrun) {
                    buttons.push({
                        action: 'gotoCampaignRun',
                        text: this.$t('btn.editCustomization')
                    });
                }

                return buttons;
            },
            calculateRightBasketBtns() {
                let buttons = [];

                if (this.isInBasket) {
                    if (this.isBundleItem) {
                        buttons.push({
                            action: 'openBundleDetailPage',
                            text: this.$t('btn.editBundle')
                        });
                    }

                    if (!this.inTreeView && (!this.campaignRunDeleted || this.isBundleItem)) {
                        buttons.push({
                            action: 'pushToWatchlist',
                            text: this.$t(this.isBundleItem ? 'btn.pushBundleToWatchlist' : 'btn.pushToWatchlist')
                        });
                    }

                    if (!this.inTreeView) {
                        buttons.push({
                            action: 'removeFromBasket',
                            text: this.$t(this.isBundleItem ? 'btn.removeBundleFromBasket' : 'btn.delete')
                        });
                    }
                }

                return buttons;
            },
            calculateRightWatchlistBtns() {
                let buttons = [];

                if (!this.inTreeView) {
                    if (!this.campaignRunDeleted) {
                        buttons.push({
                            action: 'moveToBasket',
                            text: this.$t('label.addToBasket')
                        });
                    }
                    buttons.push({
                        action: 'removeFromWatchlist',
                        text: this.$t('btn.delete')
                    });
                }

                return buttons;
            },
            getWorkflowInstanceVariables() {
                this.$store.dispatch('workflowInstance/getVariables', {
                    businessOid: this.workflowBusinessOid
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                });
            },
            getValueFromWorkflowVariables(name, defaultValue) {
                if (!this.instanceVariables) {
                    return defaultValue;
                }
                for (let prop in this.instanceVariables) {
                    if (prop === name) {
                        return this.instanceVariables[name].value;
                    }
                }
                return defaultValue;
            },
        }
    };
</script>
